import Image from 'next/image'

export default function Header() {

    return (
        <>
            <div className="relative isolate overflow-hidden bg-black flex flex-col items-center justify-center min-h-screen">
                <img
                    src="/cw_banner.png"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />
                {/* <div
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ffffff] to-[#aeaeae] opacity-10"
                        style={{
                            clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                    aria-hidden="true"
                >
                    <div
                    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ffffff] to-[#aeaeae] opacity-20"
                    style={{
                        clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
                </div> */}
                <div className="relative z-10">
                    <div className="flex flex-col items-center justify-center space-y-4">
                        <Image
                            src="/cw_logo.svg"
                            alt="Combat Warriors Logo"
                            width={350}
                            height={350}
                            className={`transition duration-500 ease-in-out cursor-default mt-12`}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
