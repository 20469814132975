import { useRouter } from 'next/router'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const formatIcon = (icon) => {
  if (!icon) return "https://clans.combatwarriors.gg/static/default_icon.png";
  if (icon.startsWith("https://cdn.discordapp.com/icons/")) return icon + "?size=1024"
  return icon;
}

const ClanCard = ({ clan, fallback, setHovered }) => {
  const router = useRouter();

  const [imgSrc, setSrc] = useState(clan?.icon ? formatIcon(clan.icon) : 'https://combatwarriors.gg/default_icon.png')

  useEffect(() => {
    setSrc(clan?.icon ? formatIcon(clan.icon) : 'https://combatwarriors.gg/default_icon.png')
  }, [clan.icon]);

  return (
    <>
      <div 
        className="h-48 w-48 mx-8 overflow-hidden rounded-lg cursor-pointer" 
        onClick={() => router.push(`/clan/${clan.tag.toLowerCase()}`)}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)} 
      >
        <Tippy content={`[${clan.tag}] ${clan.name}`} placement="top">
          <Image
            src={imgSrc}
            alt={clan.tag}
            className="h-48 w-48 object-cover	filter transition duration-250 ease-in-out opacity-50 hover:opacity-100"
            width={999}
            height={999}
            onError={(e) => {
              e.target.onerror = null
              setSrc('https://combatwarriors.gg/default_icon.png')
            }}
            placeholder='blur'
            blurDataURL={fallback}
          />
        </Tippy>
      </div>
    </>
  )
}

export default function ClanCTA() {
    const router = useRouter();

    const [topClansToPromote, setTopClansToPromote] = useState([])
    const [bottomClansToPromote, setBottomClansToPromote] = useState([])
    const [fallbackIcon, setFallbackIcon] = useState('')

    useEffect(() => {
      fetch(`/api/v1/clans?sort=random&limit=50`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.clans) {
            setTopClansToPromote(data.clans.slice(0, 25));
            setBottomClansToPromote(data.clans.slice(25, 50));
            setFallbackIcon(data.fallback);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, []);

    const [hovered, setHovered] = useState(false)

    return (
      <div className='bg-black'>
        <div className='flex flex-col items-center justify-center h-full'>
          <Marquee speed={50} gradientColor={[0, 0, 0]} play={!hovered} className='mt-8' direction='right'>
            {topClansToPromote?.length > 0 && topClansToPromote.map((clan, i) => (
              <ClanCard clan={clan} key={i} fallback={fallbackIcon} setHovered={setHovered} />
            ))}
          </Marquee>

          <div className="py-12 text-center">
            <h1 className='text-4xl font-bold text-white'>Discover Clans</h1>
            <p className='text-white mt-2'>
              Search and discover the best Combat Warriors clans!
            </p>
            <button
              className="px-4 py-2 mt-2 bg-white text-black font-semibold rounded hover:bg-gray-200 transition-colors duration-200"
              onClick={() => router.push('/clan')}
              >
                Explore Clans
            </button>
          </div>

          <Marquee speed={50} gradientColor={[0, 0, 0]} play={!hovered} className='mt-8'>
            {bottomClansToPromote?.length > 0 && bottomClansToPromote.map((clan, i) => (
              <ClanCard clan={clan} key={i} fallback={fallbackIcon} setHovered={setHovered} />
            ))}
          </Marquee>
        </div>
      </div>
    )
}