import { useEffect, useState } from 'react';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import { useRouter } from 'next/router'
import Image from 'next/image'
import toast from 'react-hot-toast'

const formatURL = (url) => {
    if (!url) return "";
    url = url.replace(/https:\/\/combatwarriors.gg/g, "https://blog.combatwarriors.gg");
    return url;
}

export default function BlogPosts({ blogPage = false }) {
    const router = useRouter();

    const [posts, setPosts] = useState([]);
    const [fetchingPosts, setFetchingPosts] = useState(true);

    useEffect(() => {
        fetch("/api/v1/blog")
            .then((res) => res.json())
            .then((data) => {
                if (!data.success) {
                    toast.error(`Failed to fetch posts!`)
                }
                else {
                    console.log(data.data)
                    if (blogPage) setPosts(data?.data);
                    else setPosts(data?.data.slice(0, 6));
                    setFetchingPosts(false);
                }
            });
    }, []);

    return (
        <section className="bg-black py-24">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-4xl font-bold tracking-tight text-white text-center mb-3">{blogPage ? "Blog" : "Latest Posts"}</h2>
                {blogPage && (
                    <span className="text-white/75 text-center text-sm block m-auto text-center">
                        Stay up to date with the latest news, updates, and more!
                    </span>
                )}
                <div className="mt-6 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {posts?.length ? posts.map((post) => (
                        <article
                            key={post.slug}
                            className="bg-[#151515] rounded-lg overflow-hidden shadow-lg transition-all cursor-pointer"
                            onClick={() => router.push(`/blog/${post.slug}`)}
                        >
                            <div className="relative h-64">
                                <Image
                                    className="object-cover"
                                    src={formatURL(post.feature_image) || "/placeholder.svg"}
                                    alt={post.title}
                                    layout="fill"
                                />
                            </div>
                            <div className="p-6">
                                <div className="flex flex-wrap gap-2 mb-1">
                                    {post?.tags?.map((tag) => (
                                        <span
                                            key={tag.name}
                                            className="text-xs font-medium text-white bg-white/10 bg-opacity-50 rounded-full px-3 py-1"
                                        >
                                            {tag.name}
                                        </span>
                                    ))}
                                </div>
                                <span className="text-gray-400 text-xs">
                                    {dayjs(post.published_at).fromNow()}
                                </span>
                                <h3 className="text-xl font-semibold text-white mb-2 line-clamp-2 mt-1">{post.title}</h3>
                                <p className="text-gray-400 mb-4 line-clamp-3">{post.meta_description || post.excerpt}</p>
                            </div>
                        </article>
                    )) : fetchingPosts ? (
                        Array.from({ length: 3 }).map((_, i) => (
                            <div
                                key={i}
                                className="bg-[#151515] rounded-lg overflow-hidden shadow-lg"
                            >
                                <div className="h-64 bg-white/10 animate-pulse"></div>
                                <div className="p-6">
                                    <div className="flex flex-wrap gap-2 mb-1">
                                        <span className="text-xs font-medium bg-white/10 animate-pulse bg-opacity-50 rounded-full h-5 w-20" />
                                    </div>
                                    <span className="text-gray-400 text-xs bg-white/10 animate-pulse h-5 w-20" />
                                    <h3 className="text-xl font-semibold text-white mb-2 line-clamp-2 mt-1 bg-white/10 animate-pulse h-5 w-full mt-4" />
                                    <p className="text-gray-400 mb-4 line-clamp-3 bg-white/10 animate-pulse h-24 w-full" />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-white text-center m-auto w-full col-span-full">
                            No blog posts found!
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}
  